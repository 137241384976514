<template>
  <div class="home">
    <harvester />
  </div>
</template>

<script>
// @ is an alias to /src
import Harvester from "@/components/Harvester";

export default {
  name: 'home',
  components: {
    Harvester,
  }
}
</script>
