<template>
  <v-card
      v-if="!loading && rewards.length"
      class="ma-2"
      outlined
      tile
  >
    <v-container fluid>
      <v-row
          dense
          no-gutters
      >
        <v-col>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-subtitle-1">
                <a
                    v-if="config.url"
                    :href="config.url"
                    class="venue-link"
                    target="_blank"
                >
                  {{ config.name }}
                  <v-icon x-small>mdi-open-in-new</v-icon>
                </a>
                <template v-else>
                  {{ config.name }}
                </template>
                <!--                <v-icon small>mdi-open-in-new</v-icon>-->
              </v-list-item-title>

              <div class="mb-1 text-caption">
                {{ rewardTokenTicker }}
              </div>

              <div class="mb-1 text-caption" style="font-size:0.65rem !important;line-height:0.8rem;">
                <div>{{ rewardTokenAddress }}</div>
              </div>

              <div
                  v-if="rewardTokenNotional"
                  class="mb-1 text-caption grey--text text--darken-2"
              >
                {{ money(rewardTokenNotional, 3) }} USDC
              </div>

              <v-list-item-subtitle class="text-caption">Pools: {{ poolCount }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>

        <v-col
            v-if="!loading"
            align-self="center"
            col="9"
        >
          <v-list
              v-if="rewards.length"
              dense>
            <template v-for="(reward, i) in rewards">
              <v-list-item :key="reward[0]">
                <v-list-item-title>
                  <div>{{ getPoolName(reward) }}</div>
                  <div class="text-caption grey--text text--darken-2">
                    <span class="d-inline-block">{{ money(getStakedNotional(reward), 3) }}</span>
                    <span class="d-inline-block ml-3">[{{ fixedZero(getStakedPct(reward), 2, 'err') }}%]</span>
                  </div>
                </v-list-item-title>
                <v-list-item-subtitle style="white-space: nowrap">
                  <div>{{ getAmount(reward) }}</div>
                  <div
                      v-if="rewardTokenNotional"
                      class="text-caption grey--text text--darken-2"
                  >
                    [{{ money(getAmountNotional(reward), 3) }} USDC]
                  </div>
                  <!--                <span style="font-size:0.8em;">{{ config.chef.rewardTokenTicker }}</span>-->
                </v-list-item-subtitle>

                <v-btn :loading="isHarvesting(reward)" color="primary" elevation="0" x-small @click="harvest(reward)">
                  Harvest
                </v-btn>
              </v-list-item>
              <v-divider v-if="i !== rewards.length - 1" class="my-2"></v-divider>
            </template>
          </v-list>

          <div v-else class="text-caption">No pools with rewards for harvest.</div>
        </v-col>

        <v-col
            v-else
            align-self="center"
        >
          <div class="text-caption">Loading rewards for harvest...</div>
        </v-col>
      </v-row>
    </v-container>

  </v-card>
</template>


<script>
import {ethers} from "ethers"
import {getMaticPoolInfo, getMaticToken} from "@/modules/vfat/matic_helpers";
import {getPoolPrices} from "@/modules/vfat/ethers_helper"
import {getParameterCaseInsensitive} from "@/modules/vfat/helpers"
import {fixedZero, money} from "@/util/formatters";
import {fetchUSDCQuote} from "@/services/1inch";
import {SwapPlatforms} from "@/config/swapPlatforms";
import {asyncForEach} from "@/util/lang";

export default {
  name: 'Venue',

  props: {
    account: String,
    config: Object,
    provider: Object,
    ethcallProvider: Object,
    prices: Object,
    gasPrice: String,
    init: [String, Number],
    show: Boolean
  },

  data: () => ({
    loading: true,
    poolCount: '-',
    rewards: [],
    tokens: {},
    harvesting: [],
    harvestingAll: false
  }),

  computed: {
    walletConfig() {
      // expected by vfat.tools
      return {
        YOUR_ADDRESS: this.account,
        provider: this.provider,
        ethcallProvider: this.ethcallProvider
      }
    },

    chef() {
      return this.config.chef
    },

    strategy() {
      this.config.strategy
    },

    rewardTokenTicker() {
      return (this.chef && this.chef.rewardTokenTicker) || null;
    },

    rewardTokenAddress() {
      return this.rewardToken && this.rewardToken.address || null;
    },

    rewardTokenNotional() {
      return this.rewardToken && this.rewardToken.usdc || null;
    },

    rewardToken() {
      return this.chef && this.chef.rewardToken || {};
    }
  },

  methods: {
    money,
    fixedZero,

    getPoolName(reward) {
      return reward.poolToken && reward.poolToken.symbol
          ? reward.poolToken.token0
              ? this.getSymbolPair(reward.poolToken)
              : reward.poolToken.symbol
          : `pool ${reward[0]}`;
    },

    getSymbolPair(poolToken) {
      if (poolToken.token0 && poolToken.token1) {
        const t0 = getParameterCaseInsensitive(this.tokens, poolToken.token0);
        const t1 = getParameterCaseInsensitive(this.tokens, poolToken.token1);
        return t0 && t1 ? `${t0.symbol}-${t1.symbol}` : undefined;
      }
    },

    getAmount(reward) {
      // console.log(reward.pendingRewardTokens * 1e18);
      return reward.pendingRewardTokens || reward[1];
    },

    getAmountNotional(reward) {
      return this.getAmount(reward) * this.rewardTokenNotional;
    },

    getIndex(reward) {
      return reward?.poolIndex !== undefined ? reward.poolIndex : reward[0];
    },

    getStakedNotional(reward) {
      return reward?.userStaked && reward?.price?.price && reward.userStaked * reward.price.price;
    },

    getStakedPct(reward) {
      return reward?.price?.staked_tvl && this.getStakedNotional(reward) / reward.price.staked_tvl * 100;
    },

    async queryChef() {
      this.$bus('info', `${this.config.name} - queryChef`);
      console.log(this.config.name, 'queryChef');
      const poolCount = parseInt(await this.chef.contract.poolLength(), 10);
      this.poolCount = poolCount;
      this.$bus('info', `${this.config.name} - poolCount: ${poolCount}`);
      console.log(this.config.name, 'poolCount', poolCount);
      await this.getRewardToken();
      await this.getPendingRewards();
    },

    async getRewardToken() {
      const rewardTokenAddress = await this.chef.contract.callStatic[this.chef.rewardTokenFunction]();
      const rewardToken = await getMaticToken(this.walletConfig, rewardTokenAddress, this.chef.address);
      console.log('rewardToken', rewardToken);
      this.$set(this.config.chef, 'rewardToken', rewardToken);
      await this.getRewardTokenNotional()
    },

    async getRewardTokenNotional() {
      if (this.rewardTokenAddress && this.config.swap === SwapPlatforms.OneInch) {
        const notional = await fetchUSDCQuote(this.rewardTokenAddress, 10 ** this.rewardToken.decimals);
        this.$set(this.chef.rewardToken, 'usdc', notional);
      }
    },

    async getPendingRewards() {
      // locate the pools that we are interested in based on having a reward
      const results = await Promise.all([...Array(this.poolCount).keys()].map(async i => await this.getPendingReward(i)));
      const pendingRewards = results.filter(p => p[1] > 0);
      const rewardsPools = pendingRewards.map(p => p[0]);

      try {
        // describe the pool
        let pools = await this.getPoolsInfo(rewardsPools);
        console.log(this.config.name, pools);

        // fallback to undescribed dataset if need be
        this.rewards = pools.some(p => p === undefined) ? pendingRewards : pools;
      } catch (e) {
        console.error(e);
        this.$bus('error', `problem getting pendingRewards for ${this.config.name}`)
        // fallback to undescribed dataset
        this.rewards = pendingRewards;
      }
    },

    async getPendingReward(poolIndex) {
      const reward = await this.chef.contract.callStatic[this.chef.pendingRewardsFunction](poolIndex, this.account)
      return [poolIndex, reward / 1e18]
    },

    async getPoolsInfo(pool_ids) {
      return await Promise.all(pool_ids.map(async i => this.getPoolInfo(i)));
    },

    async getPoolInfo(poolIndex) {
      const getPoolInfoFn = this.chef?.helpers?.getPoolInfo ? this.chef.helpers.getPoolInfo : getMaticPoolInfo;

      try {
        const poolInfo = await getPoolInfoFn(this.walletConfig, this.chef.contract, this.chef.address, poolIndex, this.chef.pendingRewardsFunction);
        return Object.assign({}, poolInfo, {poolIndex});
      } catch (e) {
        console.log(e);
      }
    },

    async getPoolsTokens(pools) {
      const tokenAddresses = [].concat.apply([], pools.filter(x => x.poolToken).map(x => x.poolToken.tokens));
      const getPoolPricesFn = this.chef?.helpers?.getPoolPrices ? this.chef.helpers.getPoolPrices : getPoolPrices;
      const lookUpTokenPrices = this.chef?.helpers?.lookUpTokenPrices;

      if (lookUpTokenPrices) {
        const prices = await lookUpTokenPrices(tokenAddresses);
        if (prices) {
          this.prices = Object.assign(this.prices, prices)
        }
      }

      // describe the tokens
      await Promise.all(tokenAddresses.map(async (address) => {
        this.tokens[address] = await getMaticToken(this.walletConfig, address, this.chef.address);
      }));

      // get prices for the tokens
      pools.forEach(pool => {
        const price = pool.poolToken ? getPoolPricesFn(this.tokens, this.prices, pool.poolToken, "matic") : undefined;
        this.$set(pool, 'price', price);
      })

      // lookup the price of the reward token
      // let rewardPrice = getParameterCaseInsensitive(this.prices, this.chef.rewardTokenAddress);
      // rewardPrice = rewardPrice && rewardPrice.usd ? rewardPrice.usd : rewardPrice;
      // console.log('prices', this.config.name, this.prices, rewardPrice);
    },

    async updatePendingReward(poolIndex) {
      const [index, reward] = await this.getPendingReward(poolIndex);
      const current = this.rewards.findIndex(r => r.poolIndex === poolIndex);
      if (current !== -1) {
        this.rewards[current].pendingRewardTokens = reward;
      }
    },

    async updatePoolsTokens() {
      await this.getPoolsTokens(this.rewards);
    },

    async harvest(reward) {
      const poolIndex = this.getIndex(reward);
      const harvestingIndex = this.harvesting.push(poolIndex) - 1;
      await this.$nextTick()

      try {
        const signer = this.provider.getSigner();
        const chef = new ethers.Contract(this.chef.address, this.chef.abi, signer);

        const earnedTokenAmount = await chef.callStatic[this.chef.pendingRewardsFunction](poolIndex, this.account) / 1e18;
        this.$bus('info', `harvesting ${earnedTokenAmount} ${this.chef.rewardTokenTicker}`)

        try {
          const gas = {gasLimit: 500000, gasPrice: this.calcGasPrice()}
          const txn = this.chef.claimFunction
              ? await chef.callStatic[this.chef.claimFunction](poolIndex, this.account, gas)
              : await chef.withdraw(poolIndex, 0, gas);
          const result = await this.provider.waitForTransaction(txn.hash);
          this.$bus('info', `done harvesting ${earnedTokenAmount} ${this.chef.rewardTokenTicker}`);
          console.log(result);

          reward[1] = 0;

          this.$bus('info', `refreshing ${this.config.name} pool ${poolIndex}`);
          window.setTimeout(() => this.updatePendingReward(poolIndex), 0);
        } catch (e) {
          const msg = `error harvesting ${this.config.name} pool ${this.getPoolName(reward)}: ${e.message}`;
          this.$bus('error', msg)
          console.log(msg);
          console.error(e);
        }
      } catch (e) {
        const msg = `error while preparing to harvest ${this.config.name} pool ${this.getPoolName(reward)}: ${e.message}`;
        this.$bus('error', msg)
        console.log(msg);
      } finally {
        this.harvesting.splice(harvestingIndex, 1);
        console.log(this.harvesting);
      }
    },

    calcGasPrice() {
      return ethers.utils.parseUnits(...this.gasPrice.split(' '));
    },

    isHarvesting(reward) {
      if (this.harvestingAll) {
        return true
      }
      const poolIndex = this.getIndex(reward);
      const retval = !!(this.harvesting.find(i => i === poolIndex));
      // console.log(this.harvesting, reward, retval);
      return retval;
    },

    async harvestAll() {
      this.harvestingAll = true;
      await Promise.all(this.rewards.map(async reward => await this.harvest(reward)));
      this.harvestingAll = false;
    },

    async poll() {
      if (this.rewards.length > 0) {
        try {
          if (!this.loading) {
            await this.getRewardTokenNotional();
            await Promise.all(this.rewards.map(async r => await this.updatePendingReward(this.getIndex(r))));
          }
          await this.updatePoolsTokens(this.pools);
        } catch (e) {
          console.error(e);
        }
        this.loading = false;
        window.setTimeout(() => this.poll(), 60000);
      }
    }
  },

  watch: {
    init: {
      async handler(val) {
        if (val === this.config.name) {
          await this.$nextTick();

          if (this.chef) {
            const contract = new ethers.Contract(this.chef.address, this.chef.abi, this.provider);
            this.$set(this.config.chef, 'contract', contract);
            await this.queryChef()

            this.poll().then(r => console.log(this.config.name, 'polling started'));
          } else {
            console.log(`${this.config.name} is chef-less`);
          }

          this.$emit('initialized', this.config.name, this.rewards.length > 0);
        }
      },
      immediate: true
    }
  },

  async mounted() {
    if (this.config && (this.config.chef || this.config.strategy) && this.provider) {
    } else {
      console.log('No Config!')
    }
  }
}
</script>


<style lang="scss" scoped>
.venue-link {
  color: #454545;
  text-decoration: none;
}
</style>