export const Networks = {
    Polygon: {
        "chainId": "0x89",
        "chainName": "Matic Mainnet",
        "nativeCurrency": {
            "name": "Matic",
            "symbol": "MATIC",
            "decimals": 18
        },
        "rpcUrls": [
            "https://rpc-mainnet.matic.network",
            "wss://ws-mainnet.matic.network"
        ]
    },
}

export const networkNameFromId = function (id) {
  for(let network of Object.values(Networks)) {
    let networkId = parseInt(network.chainId, 16)
    if (networkId === id) {
      return network.chainName
    }
  }
  return "Unknown Network"
}
