/*
 *
 * NOTE:
 * making ethers_helpers.js compliant right now is too much work
 *
 * just copying out functions that we can to use outside of the
 * vfat library's implementation proper
 *
 */

export function getParameterCaseInsensitive(object, key) {
  return object[Object.keys(object)
    .find(k => k.toLowerCase() === key.toLowerCase())
  ];
}

const chunk = (arr, n) => arr.length ? [arr.slice(0, n), ...chunk(arr.slice(n), n)] : []

export const lookUpPrices = async function(id_array) {
  const prices = {};
  for (const id_chunk of chunk(id_array, 50)) {
    let ids = id_chunk.join('%2C')
    let res = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=' + ids + '&vs_currencies=usd');
    res = await res.json()
    // let res = await $.ajax({
    //   url: 'https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=' + ids + '&vs_currencies=usd',
    //   type: 'GET',
    // })
    for (const [key, v] of Object.entries(res)) {
      if (v.usd) prices[key] = v;
    }
  }
  return prices
}

export async function ajax(options) {
    let res = await fetch(options.url);
    return await res.json();
}

// circumvent jquery's ajax dependency
export const $ = {
  ajax
}

