import Vue from 'vue'

const _bus = new Vue();

export default {
  install (Vue) {
    Vue.prototype.$bus = (...args) => {
      const name = args.shift();
      const _name = 'on' + name.charAt(0).toUpperCase() + name.slice(1);
      _bus.$emit(_name, ...args);
    }
    
    Vue.mixin({
      beforeCreate() {
        const options = this.$options.bus;
        
        if (options) {
          this.$_bus = [];
          
          for (let event of Object.keys(options)) {
            const handler = options[event].bind(this);
            _bus.$on(event, handler);
            this.$_bus.push({key: event, handler});
          }
        }
      },

      beforeDestroy() {
        if (this.$_bus) {
          for (let listener of this.$_bus) {
            _bus.$off(listener.event, listener.handler);
          }
        }
      }
    })
  }
}
