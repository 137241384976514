<template>
  <v-card
      class="logger"
      color="transparent"
      tile
      :class="computedClasses"
      style="width: 100%"
  >

    <logger-row
        v-if="myCompact"
        :message="lastMessage"
        @click="myCompact = !myCompact"
    >
    </logger-row>

    <template v-else>
      <v-toolbar dense flat class="mb-2">
        <v-toolbar-title class="overline">Logs</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon small @click="clearLogs">
          <v-icon small>mdi-trash-can-outline</v-icon>
        </v-btn>

        <v-btn icon small @click="myCompact = !myCompact">
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <div class="logger-rows">
        <logger-row v-for="(message, i) in messages" :key="i" :message="message" class="pb-1"></logger-row>
      </div>
    </template>

  </v-card>
</template>


<script>
import dayjs from "dayjs";
import LoggerRow from "@/components/LoggerRow";

export default {
  name: 'Logger',
  components: {LoggerRow},
  props: {
    compact: Boolean
  },

  data: () => ({
    messages: [],
    myCompact: false
  }),

  computed: {
    lastMessage() {
      return this.messages && this.messages[0] || {};
    },

    computedClasses() {
      return {compact: this.myCompact}
    }
  },

  methods: {
    addMessage(level, message) {
      this.messages.unshift({
        ts: dayjs(),
        level: level,
        message: message
      })
    },

    clearLogs() {
      this.messages = [];
      this.$bus('info', 'Logs cleared.')
    }
  },

  bus: {
    onInfo(message) {
      this.addMessage('info', message);
    },

    onWarning(message) {
      this.addMessage('warning', message);
    },

    onError(message) {
      this.addMessage('error', message);
    }
  },

  mounted() {
    this.myCompact = this.compact;
  }
}
</script>


<style lang="scss" scoped>
.logger {
  //display: table;
  width: 100%;
  font-size: 0.9rem;
  height: 200px;

  &.compact {
    height: 1.5rem;
    cursor: pointer;
  }

  &-rows {
    height: calc(100% - 48px);
    overflow-y: auto;
  }

  //border-collapse: collapse;
  //
  //&-row {
  //  display: table-row;
  //
  //  + &-row {
  //    > * {
  //      border: 1px solid #ccc;
  //    }
  //  }
  //
  //  &:after {
  //    display: table-cell;
  //    padding: 3px 6px;
  //    color: rgba(0, 0, 0, .35);
  //    border: 1px solid #ccc;
  //    content: attr(data-date);
  //    vertical-align: top;
  //  }
  //
  //  code,
  //  &:after {
  //    -webkit-animation: flash 1s;
  //    -moz-animation: flash 1s;
  //    -ms-animation: flash 1s;
  //    animation: flash 1s;
  //  }
  //}
  //
  //.message {
  //  width: 100%;
  //  white-space: pre-wrap;
  //  padding: 3px 5px;
  //  display: table-cell;
  //  font-family: monospace;
  //  font-size: 13px;
  //  vertical-align: middle;
  //
  //}
  //
  //
  //
  //@keyframes flash {
  //  0% {
  //    background: rgba(255, 240, 0, .25);
  //  }
  //  100% {
  //    background: none;
  //  }
  //}
}
</style>