import {maticTokens} from "@/modules/vfat/matic_helpers";
import {fetchApi} from "@/services/fetchApi";

let USDCTokenAddress;

const QuoteEndpoint = 'https://api.1inch.exchange/v3.0/137/quote';

export const fetchQuote = async (fromTokenAddress, toTokenAddress, amount) => {
    const params = new URLSearchParams({
        fromTokenAddress,
        toTokenAddress,
        amount
    });
    const url = `${QuoteEndpoint}?${params.toString()}`
    const data = await fetchApi(url);
    return data.toTokenAmount / 10 ** data.toToken.decimals;
}

export const fetchUSDCQuote = async (fromTokenAddress, amount) => {
    if (!USDCTokenAddress) {
        const usdcToken = maticTokens.find(t => t.symbol === 'USDC');
        USDCTokenAddress = usdcToken.contract;
    }

    return await fetchQuote(fromTokenAddress, USDCTokenAddress, amount);
}
