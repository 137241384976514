// format number to money
export const money = (num, fixed) => {
    num = parseFloat(num) || 0;
    fixed = parseInt(fixed) || 0;
    let o = {style: 'decimal', minimumFractionDigits: fixed, maximumFractionDigits: fixed};
    return new Intl.NumberFormat('en-US', o).format(num);
}

export const fixed = (num, dec) => dec ? Number(num).toFixed(dec) : Number(num).toFixed(8);

export const fixedZero = (num, dec, zero = '-') => num ? fixed(num, dec) : zero;
