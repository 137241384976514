<template>
  <v-app fill-height>
    <v-main style="padding-bottom:30px;">
      <harvester/>
    </v-main>

    <v-footer padless style="width:100%;position:fixed;bottom:0;">
      <logger compact/>
    </v-footer>

  </v-app>
</template>

<script>
import Harvester from "@/components/Harvester";
import Logger from "@/components/Logger";

export default {
  name: 'App',

  components: {
    Logger,
    Harvester,
  },

  data: () => ({
    //
  }),
};
</script>
