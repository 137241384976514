import {cafeSwap, pearZap, polyBeta, polyQuail, polyShield, singular, synapse} from "@/venues";

// maxfi: 0xD4A149D26e63665c70c0aEFd644fc4a57288F766
// stablefi: 0x1E9D9468DCa9491C7e6aE46079eB39d18f341A9f
const _address = (process.env.NODE_ENV === 'development') ? '0x1E9D9468DCa9491C7e6aE46079eB39d18f341A9f' : undefined;

const _config = [
    {
        name: 'stableFi',
        address: '0x1E9D9468DCa9491C7e6aE46079eB39d18f341A9f',
        venues: [
            cafeSwap(),
            // // koge(),
            // // pearZap(),
            // // polyBeta(),
            // // polyShield(),
            // // polyQuail(),
            synapse()
        ]
    },
    {
        name: 'maxFi',
        address: '0xD4A149D26e63665c70c0aEFd644fc4a57288F766',
        venues: [
            cafeSwap(),
            // koge(),
            // polyBeta(),
            // polyShield(),
            singular(),
        ]
    },
]


export const loadConfig = async (address) => {
    console.log(address);
    const venue_list = _config.find(c => c.address.toLowerCase() === (_address || address).toLowerCase());
    const venues = venue_list && venue_list.venues || [];
    const venues_config = await Promise.all(venues);
    return {
        address: _address || address,
        venues: venues_config
    };
}