<template>
  <v-container flat>
    <v-card
        :loading="loading"
        class="overflow-hidden"
        style="padding-top: 64px;"
    >
      <v-app-bar
          absolute
          color="white"
          sticky
      >
        <div>
          <div class="text-h5">Harvester</div>
          <div class="caption grey--text text--darken-3">{{ myAddress }}</div>
        </div>

        <v-spacer></v-spacer>

        <v-divider vertical></v-divider>

        <v-btn :disabled="walletConnected" small text @click="connectWallet">connect wallet</v-btn>
        <v-btn :disabled="!walletConnected" small text @click="changeWallet">change wallet</v-btn>

        <v-divider vertical></v-divider>

        <v-btn :disabled="this.venueInit.length > 0" small text @click="harvestAll">harvest all</v-btn>

        <v-divider vertical></v-divider>

        <v-responsive max-width="180px">
          <v-select
              v-model="gasPrice"
              dense
              flat
              solo-inverted
              rounded
              class="ml-6 pt-6"
              :items="gasPrices"
              x-small
          >
          </v-select>
        </v-responsive>
      </v-app-bar>

      <v-sheet
          class="overflow-y-auto"
          style="height:calc(100vh - 125px);"
      >
        <v-container
            v-if="!loading"
            fluid
        >
          <v-alert
              v-show="venueInit.length"
              type="info"
          >
            checking {{ nextInit }} for rewards
          </v-alert>
          <v-row
              v-for="(config, i) in fundConfig.venues"
              :key="i"
              dense
              no-gutters
          >
            <v-col>
              <venue
                  v-if="config.show"
                  :ref="config.name"
                  :account="myAddress"
                  :config="config"
                  :init="nextInit"
                  :prices="prices"
                  :provider="provider"
                  :ethcall-provider="ethcallProvider"
                  :gas-price="gasPrice"
                  @initialized="venueInitialized"
              >
              </venue>
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>
      <!--      </v-card>-->

    </v-card>
  </v-container>
</template>


<script>
import {web3Modal} from "@/modules/web3/web3Modal";
import {Networks, networkNameFromId} from "@/modules/web3/networks";
import {ethers} from "ethers"
import {loadConfig} from "@/config/fund"
import Venue from "@/components/Venue";
import {getMaticPrices} from "@/modules/vfat/matic_helpers";
import * as ethcall from "ethcall";

import {asyncForEach} from "@/util/lang";


const TARGET_NETWORK = Networks.Polygon

const _print = function (s) {
  console.log(s);
}

export default {
  name: 'Harvester',

  components: {Venue},

  props: {},

  data: () => ({
    fundConfig: {},
    walletConnected: false,
    walletProvider: null,
    provider: null,
    ethcallProvider: null,
    metaMaskInstalled: true,
    myAddress: null,
    prices: {},
    venueInit: [],
    loading: true,
    gasPrices: [
      '10 gwei',
      '20 gwei',
      '30 gwei',
      '40 gwei',
      '50 gwei',
      '60 gwei',
    ],
    gasPrice: '30 gwei'
  }),

  computed: {
    nextInit() {
      return this.venueInit.length && this.venueInit[0]
    }
  },

  methods: {
    async changeWallet() {
      let cached = web3Modal.cachedProvider;
      web3Modal.clearCachedProvider()
      await this.connectWallet(() => window.location.reload())
      if (!web3Modal.cachedProvider) {
        web3Modal.setCachedProvider(cached)
      }
    },

    async connectWallet() {
      this.$bus('info', 'connecting wallet');
      const walletProvider = this.walletProvider = await web3Modal.connect()

      // Subscribe to accounts change
      walletProvider.on('accountsChanged', (accounts) => {
        console.log(accounts);
        if (accounts === undefined || accounts.length === 0) {
          web3Modal.clearCachedProvider()
        }
        window.location.reload()
      });

      // Subscribe to chainId change
      walletProvider.on("chainChanged", (chainId) => {
        console.log('chainChanged', chainId);
        window.location.reload()
      });

      // Subscribe to provider connection
      walletProvider.on("connect", (info) => {
        this.$bus('info', 'wallet provider connected');
        console.log('connect', info);
      });

      // Subscribe to provider disconnection
      walletProvider.on("disconnect", (error) => {
        this.$bus('error', 'wallet provider disconnected')
        console.log('disconnect', error);
      });

      const provider = this.provider = new ethers.providers.Web3Provider(walletProvider);
      const connectedNetwork = await provider.getNetwork();
      const targetNetworkId = parseInt(TARGET_NETWORK.chainId, 16);

      if (connectedNetwork.chainId === targetNetworkId) {
        this.walletConnected = true;
      } else {
        this.$bus('warning', `You are connected to ${networkNameFromId(connectedNetwork.chainId)}, please switch to ${TARGET_NETWORK.chainName} network`);
        _print(`You are connected to ${networkNameFromId(connectedNetwork.chainId)}, please switch to ${TARGET_NETWORK.chainName} network`);
      }
    },

    async initWallet(callback) {
      if (web3Modal.cachedProvider) {
        await this.connectWallet()
      }

      if (this.walletProvider) {
        const provider = new ethers.providers.Web3Provider(walletProvider);
        const connectedNetwork = await provider.getNetwork();
        const targetNetworkId = parseInt(targetNetwork.chainId, 16);

        if (connectedNetwork.chainId === targetNetworkId) {
          this._start(callback);
        } else {
          this.$bus('warning', `You are connected to ${networkNameFromId(connectedNetwork.chainId)}, please switch to ${TARGET_NETWORK.chainName} network`);
          _print(`You are connected to ${networkNameFromId(connectedNetwork.chainId)}, please switch to ${TARGET_NETWORK.chainName} network`)
        }
      }
    },

    async initEthers() {
      // const App = {};
      try {
        if (this.walletProvider) {
          try {
            const accounts = await this.walletProvider.request({method: 'eth_requestAccounts'});
            this.myAddress = accounts[0];

            // Todo: look into ethcall more for other networks
            this.ethcallProvider = new ethcall.Provider()
            await this.ethcallProvider.init(this.provider);

            // console.log(App.YOUR_ADDRESS);
          } catch (e) {
            // User denied account access...
            this.$bus('error', 'User denied account access');
          }
        } else {
          this.$bus('error', 'No MetaMask. Not initializing.');
          return;
        }

      } catch (e) {
        console.error(e)
      }

      if (!this.myAddress || !ethers.utils.isAddress(this.myAddress)) {
        throw 'Could not initialize your address. Make sure your address is checksum valid.'
      }
    },

    async initConfig(address) {
      this.fundConfig = await loadConfig(address);
      if (this.fundConfig.address != this.myAddress) {
        // address is overwritten by dev configuration
        this.myAddress = this.fundConfig.address;
      }
      console.log('config loaded', this.myAddress);
      this.$bus('info', `config loaded ${this.myAddress}`);
      this.venueInit = this.fundConfig.venues.map(v => v.name);
      this.$bus('info', `config loaded ${this.venueInit.join(',')}`);
      this.fundConfig.venues.forEach(v => v.show = true);
    },

    venueInitialized(name, hasRewards) {
      this.$bus('info', `initialized ${name}`)
      if (!hasRewards) {
        const v = this.fundConfig.venues.find(v => v.name === name);
        v.show = false;
      }
      if (this.venueInit.length) {
        window.setTimeout(() => this.venueInit.splice(this.venueInit.indexOf(name), 1), 1000);
      }
    },

    async harvestAll() {
      console.log('harvestAll')
      await Promise.all(this.fundConfig.venues.filter(v => v.show).map(async config => {
        const venue = this.$refs[config.name];
        if (venue) {
          await venue[0].harvestAll();
        }
      }));
    }
  },

  async mounted() {
    await this.$nextTick();
    await this.connectWallet();
    await this.initEthers();
    await this.initConfig(this.myAddress)

    this.prices = await getMaticPrices();
    this.loading = false;
    this.$bus('info', 'Harvester initialized.')
  },

  async created() {
  }
}
</script>


<style lang="scss" scoped>

</style>