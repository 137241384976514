//
// NOTE:
// while we're creating chunks, we aren't even using some of them,
// so to speed up the build and overall packaging we'll comment out
// the venues that we aren't using at the moment
//
// TODO: if adding back a venue to the fund config just uncomment it
//
// ----------------------------------------------------------------------------

// export const adamant            = async () => (await import(/* webpackChunkName: "adamant"              */ '@/venues/adamant')).default
// export const barbershop         = async () => (await import(/* webpackChunkName: "barbershop"           */ '@/venues/barbershop')).default
export const cafeSwap           = async () => (await import(/* webpackChunkName: "cafeSwap"             */ '@/venues/cafeSwap')).default
// export const bcharity           = async () => (await import(/* webpackChunkName: "bcharity"             */ '@/venues/bcharity')).default
// export const berryFarms         = async () => (await import(/* webpackChunkName: "berryFarms"           */ '@/venues/berryFarms')).default
// export const delirium           = async () => (await import(/* webpackChunkName: "delirium"             */ '@/venues/delirium')).default
// export const dinoSwap           = async () => (await import(/* webpackChunkName: "dinoSwap"             */ '@/venues/dinoSwap')).default
// export const hermes             = async () => (await import(/* webpackChunkName: "hermes"               */ '@/venues/hermes')).default
// export const iceman             = async () => (await import(/* webpackChunkName: "iceman"               */ '@/venues/iceman')).default
// export const kavian             = async () => (await import(/* webpackChunkName: "kavian"               */ '@/venues/kavian')).default
// export const orbisFarm          = async () => (await import(/* webpackChunkName: "orbisFarm"            */ '@/venues/orbisFarm')).default
export const pearZap            = async () => (await import(/* webpackChunkName: "pearZap"              */ '@/venues/pearZap')).default
// export const polyAlpha          = async () => (await import(/* webpackChunkName: "polyAlpha"            */ '@/venues/polyAlpha')).default
export const polyBeta           = async () => (await import(/* webpackChunkName: "polyBeta"             */ '@/venues/polyBeta')).default
export const polyDragon         = async () => (await import(/* webpackChunkName: "polyDragon"           */ '@/venues/polyDragon')).default
// export const polyDyson          = async () => (await import(/* webpackChunkName: "polyDyson"            */ '@/venues/polyDyson')).default
// export const polygonFarm        = async () => (await import(/* webpackChunkName: "polygonFarm"          */ '@/venues/polygonFarm')).default
// export const polyPulsarGamma    = async () => (await import(/* webpackChunkName: "polyPulsarGamma"      */ '@/venues/polyPulsarGamma')).default
// export const polyPupBall        = async () => (await import(/* webpackChunkName: "polyPupBall"          */ '@/venues/polyPupBall')).default
// export const polyPupCollar      = async () => (await import(/* webpackChunkName: "polyPupCollar"        */ '@/venues/polyPupCollar')).default
export const polyQuail          = async () => (await import(/* webpackChunkName: "polyQuail"            */ '@/venues/polyQuail')).default
export const polyShield         = async () => (await import(/* webpackChunkName: "polyShield"           */ '@/venues/polyShield')).default
// export const polyYork           = async () => (await import(/* webpackChunkName: "polyYork"             */ '@/venues/polyYork')).default
// export const polyYeld2          = async () => (await import(/* webpackChunkName: "polyYeld2"            */ '@/venues/polyYeld2')).default
// export const stableMatic        = async () => (await import(/* webpackChunkName: "stableMatic"          */ '@/venues/stableMatic')).default
export const singular           = async () => (await import(/* webpackChunkName: "singular"             */ '@/venues/singular')).default
// export const swamp              = async () => (await import(/* webpackChunkName: "swamp"                */ '@/venues/swamp')).default
export const synapse            = async () => (await import(/* webpackChunkName: "synapse"              */ '@/venues/synapse')).default
// export const tamago             = async () => (await import(/* webpackChunkName: "tamago"               */ '@/venues/tamago')).default