<template>
    <div class="logger-row" style="display:flex; width:100%;" @click="$emit('click', $event)">
      <div style="flex: 90px 0 0; padding-right: 6px; min-width: 42px;">
        <v-chip v-if="message && message.level"
            x-small
            class="logger-level"
            label
            outlined
            :color="levelColor(message.level)"
        >
          <span class="caption">{{ message.level }}</span>
        </v-chip>
      </div>
      <div style="flex: 100% 1 1; width: 0; overflow: hidden;">
        <code>{{ message.message }}</code>
      </div>
      <div class="logger-ts" style="flex: auto 0 0;">
        <code>{{ message.ts && message.ts.format('YY/MM/DD, HH:mm:ss') }}</code>
      </div>
    </div>
</template>


<script>
export default {
  name: 'LoggerRow',

  props: {
    message: Object
  },

  data: () => ({}),

  methods: {
    levelColor(level) {
      if (level === 'info') return 'blue';
      if (level === 'warning') return 'orange';
      if (level === 'error') return 'red';
    }
  }
}
</script>


<style lang="scss" scoped>
.logger-row {
  padding: 0 8px;

  .logger-level {
    font-size: 0.75rem !important;
    font-weight: 400;
    letter-spacing: 0.033em !important;
    line-height: 1.25rem;
    text-transform: uppercase;

  }

  .logger-ts {
    opacity: 0.7;
  }

  code {
    background-color: transparent !important;
  }
}
</style>